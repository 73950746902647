<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { SearchButton, AddAnnoucementButton } from "@entities/announcement/";
import { ChangeRegionButton } from "@entities/user";
import {
	InputField,
	SvgTemplate,
	TYPOGRAPHY_TYPES,
	Typography,
	Image,
	DropdownPolicy,
} from "@shared/ui";
import { useScreenSize, isNonEmptyString } from "@shared/lib";
import { BUCKET_URL, BUCKET_PREFIX_MEDIUM, NOT_IMAGES } from "@shared/const";
import { CatalogModal } from "@features/CatalogModal";
import { ChangeLocaleBtn } from "@widgets/ChangeLocale";
import { useTranslation } from "i18next-vue";

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useTranslation();

const { screenSize, SCREEN_SIZES_LIST } = useScreenSize();

const searchModel = ref(route.params.term);

const isSubHeaderEnabled = computed(() => {
	if (!store.getters["announcement/activeAdEditor"]) {
		return false;
	}
	return !route.meta.subHeaderDisable;
});

const isSearchModelEmpty = computed(() => {
	return isNonEmptyString(searchModel?.value);
});

const routes = {
	home: { name: "Home" },
	profile: { name: "Profile" },
	announcements: { name: "Announcements" },
	favorites: { name: "Favorites" },
	myAnnouncements: { name: "myAnnouncements" },
	chats: { name: "Chats" },
};

const isActive = (path) => {
	if (path.name === routes.profile.name) {
		return route.name === path.name || route.name === routes.myAnnouncements.name;
	}

	return route.name === path.name;
};

watch(route, () => {
	if (route.name !== "SearchAnnouncementPage") searchModel.value = null;
});

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const currentUser = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const avatarUrl = computed(() => {
	if (currentUser.value?.photo) {
		return `${BUCKET_URL}${BUCKET_PREFIX_MEDIUM}/user/${currentUser.value.user_id}/${currentUser.value.photo}`;
	}
	return NOT_IMAGES.ANONYM;
});

const searchAnnouncement = () => {
	if (isSearchModelEmpty.value) {
		router.push({ name: "SearchAnnouncementPage", params: { term: searchModel.value } });
	}
};

const searchPlaceholder = computed(() => {
	return screenSize.value !== SCREEN_SIZES_LIST.LG
		? t("headerSearchPlaceholder")
		: t("headerSearchPlaceholder2");
});

const dropdownList = ref(false);

const modalRefPolicy = ref(null);

const openDropdownList = () => {
	if (dropdownList.value) {
		modalRefPolicy.value.closeDropdown();
		dropdownList.value = false;

		return;
	}
	dropdownList.value = !dropdownList.value;
	modalRefPolicy.value.openDropdown();
};

const closeDropdownList = () => {
	setTimeout(() => {
		dropdownList.value = false;
		modalRefPolicy.value.closeDropdown();
	}, 100);
};

const catalogModal = ref(null);

const selectGroup = (id) => {
	router.push({ name: "Announcements", query: { group_id: id } });
};

const selectTagCategory = (id) => {
	router.push({ name: "Announcements", query: { tag_category_id: id } });
};

const totalUnseenCounter = computed(() => store.getters["chat/getTotalUnseenCounter"]);
</script>

<template>
	<header class="header-nav">
		<div class="header-nav__overhead">
			<div class="header-nav__content container">
				<div class="header-nav__groups">
					<RouterLink
						:class="[
							'header-nav__selector-element',
							{ 'header-nav__main-active': isActive(routes.home) },
						]"
						to="/"
					>
						<SvgTemplate class="header-nav__icon" name="wire-house" />
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
							{{ t("headerMain") }}
						</Typography>
					</RouterLink>
					<button
						type="button"
						:class="[
							'header-nav__selector-element',
							{ 'header-nav__main-active': isActive(routes.announcements) },
						]"
						@click="catalogModal.open()"
					>
						<SvgTemplate class="header-nav__icon" name="list-nav" />
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
							{{ t("headerCatalog") }}
						</Typography>
					</button>
					<div
						class="header-nav__policy"
						@click="openDropdownList"
						@mouseleave="closeDropdownList"
						@blur="closeDropdownList"
					>
						<SvgTemplate class="header-nav__icon" name="laptopcomputer" />
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
							{{ t("headerPolicy") }}
						</Typography>
						<SvgTemplate
							class="header-nav__chevron-icon"
							:class="{ 'header-nav__chevron-icon_open': dropdownList }"
							name="polygon"
						/>
						<DropdownPolicy ref="modalRefPolicy" />
					</div>
					<RouterLink
						v-if="isUserLoggedIn"
						to="/chats"
						:class="['header-nav__link ', { 'header-nav__main-active': isActive(routes.chats) }]"
					>
						<div
							class="header-nav__chats"
							:class="{ 'header-nav__chats_unseen': totalUnseenCounter }"
						>
							<SvgTemplate
								class="header-nav__icon"
								:name="totalUnseenCounter ? 'envelope' : 'envelope-open'"
							/>
						</div>
						<Typography :type="TYPOGRAPHY_TYPES.BUTTON_1">{{ t("headerChats") }}</Typography>
					</RouterLink>
				</div>
				<div class="header-nav__user">
					<RouterLink
						to="favorites"
						:class="['header-nav__link', { 'header-nav__main-active': isActive(routes.favorites) }]"
					>
						<SvgTemplate class="header-nav__icon" name="heart-nav" />
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
							{{ t("headerFavorites") }}
						</Typography>
					</RouterLink>
					<ChangeLocaleBtn class="header-nav__locale" />
					<RouterLink
						to="profile"
						:class="['header-nav__link', { 'header-nav__main-active': isActive(routes.profile) }]"
					>
						<template v-if="isUserLoggedIn">
							<div class="avatar__circle_block">
								<Image :src="avatarUrl" :alt="аватарка" />
							</div>
							<Typography
								class="header-nav__link-name"
								:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
							>
								{{ currentUser.first_name }}
							</Typography>
						</template>
						<template v-else>
							<SvgTemplate class="header-nav__icon" name="user-header" />
							<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
								{{ t("headerEntrance") }}
							</Typography>
						</template>
					</RouterLink>
				</div>
			</div>
		</div>
		<nav v-if="isSubHeaderEnabled" class="header-nav__content container header-nav__subhead">
			<div class="header-nav__search-container">
				<RouterLink class="header-nav__logo" to="/">
					<img class="header-nav__logo" src="/img/Logo.svg" alt="Rexpat логотип" />
				</RouterLink>
				<InputField
					class="header-nav__search"
					v-model="searchModel"
					:placeholder="searchPlaceholder"
					v-on:keyup.enter="searchAnnouncement"
				/>
				<SearchButton @click="searchAnnouncement" :disabled="!isSearchModelEmpty" />
				<ChangeRegionButton v-if="!isActive(routes.profile)" />
			</div>
			<AddAnnoucementButton />
		</nav>
	</header>
	<CatalogModal
		ref="catalogModal"
		@select-group="selectGroup"
		@select-tag-category="selectTagCategory"
	>
		<template #title>{{ t("groupsModalTitle") }}</template>
	</CatalogModal>
</template>

<styles lang="scss" scoped>
@import "./HeaderNav.scss";
</styles>
