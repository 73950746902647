<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ChangeRegionButton } from "@entities/user";
import { InputField, SvgTemplate, DropdownPolicy } from "@shared/ui";
import { useElementBounding, useMutationObserver, useScroll } from "@vueuse/core";
import { withPx, isNonEmptyString } from "@shared/lib";
import { ChangeLocaleBtn } from "@widgets/ChangeLocale";
import { useTranslation } from "i18next-vue";

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useTranslation();

const searchModel = ref(route.params.term);

const isActive = (path) => {
	return route.path === path;
};

const isSubHeaderEnabled = computed(() => {
	return !route.meta.subHeaderDisable;
});

const isHeaderEnabled = computed(() => {
	if (!store.getters["announcement/activeAdEditor"]) {
		return false;
	}
	return !route.meta.headerDisable;
});

watch(route, () => {
	if (route.name !== "SearchAnnouncementPage") searchModel.value = null;
});

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const isSearchModelEmpty = computed(() => {
	return isNonEmptyString(searchModel?.value);
});

const searchAnnouncement = () => {
	if (isSearchModelEmpty.value) {
		router.push({ name: "SearchAnnouncementPage", params: { term: searchModel.value } });
	}
};

const isTeleportEmpty = ref(true);

const teleportRef = ref(null);

const overheadRef = ref(null);

const headerRef = ref(null);

const overheadBounding = useElementBounding(overheadRef);

const headerBounding = useElementBounding(headerRef);

const { y, directions } = useScroll(document);

const currentScrollDirectionValue = ref(null);
const changeDirectionY = ref(null);

watch(
	directions,
	() => {
		if (directions.top && currentScrollDirectionValue.value !== "top") {
			currentScrollDirectionValue.value = "top";
			changeDirectionY.value = y.value;
		} else if (directions.bottom && currentScrollDirectionValue.value !== "bottom") {
			currentScrollDirectionValue.value = "bottom";
			changeDirectionY.value = y.value;
		}
	},
	{ deep: true }
);

const withTransition = computed(() => {
	return y.value > 100;
});

const isHeaderVisible = computed(() => {
	if (y.value > 100) {
		if (currentScrollDirectionValue.value === "top") {
			return changeDirectionY.value - y.value > 10;
		}
		if (currentScrollDirectionValue.value === "bottom") {
			return y.value - changeDirectionY.value < 100;
		}
	}
	return false;
});

const headerTranslateY = computed(() => {
	if (isHeaderVisible.value || y.value <= 100) {
		return 0;
	}
	return -headerBounding.height.value + overheadBounding.height.value - 2;
});

useMutationObserver(
	teleportRef,
	(mutations) => {
		if (mutations[0].addedNodes.length > 0) {
			isTeleportEmpty.value = false;
		} else {
			isTeleportEmpty.value = true;
		}
	},
	{ childList: true }
);

onMounted(() => {
	if (teleportRef?.value?.childElementCount > 0) {
		isTeleportEmpty.value = false;
	}
});

const dropdownList = ref(false);
const refDropdownPolicy = ref(null);

const toggleDropdownList = () => {
	dropdownList.value = true;
	refDropdownPolicy.value.openDropdown();
};

const hidenDropdownList = () => {
	dropdownList.value = false;
	refDropdownPolicy.value.closeDropdown();
};

watch(
	() => dropdownList.value,
	(newIsActive, oldIsActive) => {
		if (newIsActive && !oldIsActive) {
			document.addEventListener("scroll", hidenDropdownList);
			setTimeout(() => {
				document.addEventListener("click", hidenDropdownList);
			}, 100);
		} else if (!newIsActive && oldIsActive) {
			document.removeEventListener("scroll", hidenDropdownList);
			document.removeEventListener("click", hidenDropdownList);
		}
	}
);

const totalUnseenCounter = computed(() => store.getters["chat/getTotalUnseenCounter"]);
</script>

<template>
	<header
		v-show="isHeaderEnabled"
		class="header-nav-mobile"
		:class="{ 'header-nav-mobile_transition': withTransition }"
		:style="{ transform: `translate3d(0,${withPx(headerTranslateY)},0)` }"
		ref="headerRef"
	>
		<div class="header-nav-mobile__overhead" ref="overheadRef">
			<div class="header-nav-mobile__overhead-left">
				<div class="header-nav-mobile__icon-policy">
					<SvgTemplate
						class="header-nav-mobile__icon"
						name="laptopcomputer"
						@click="toggleDropdownList"
					/>
					<DropdownPolicy ref="refDropdownPolicy" />
				</div>
				<ChangeLocaleBtn />
			</div>
		</div>
		<nav v-if="isSubHeaderEnabled" class="header-nav-mobile__content">
			<div class="header-nav-mobile__banner">
				<RouterLink v-if="isActive('/')" to="/">
					<img class="header-nav-mobile__logo" src="/img/Logo.svg" alt="Rexpat логотип" />
				</RouterLink>
				<div id="teleport_back_btn_here" ref="teleportRef"></div>
				<InputField
					class="header-nav-mobile__search"
					v-model="searchModel"
					:placeholder="t('headerSearchPlaceholder2')"
					:isMobileHeader="true"
					v-on:keyup.enter="searchAnnouncement"
				>
					<template #rigth>
						<SvgTemplate
							class="search-button__icon-mobile"
							name="magnifyingglass"
							@click="searchAnnouncement"
						/>
					</template>
				</InputField>
				<div class="header-nav-mobile__container">
					<ChangeRegionButton v-if="!isActive('/profile')" :header-mobile="true" />
				</div>
				<RouterLink to="/chats" class="header-nav-mobile__chats-link" v-if="isUserLoggedIn">
					<div>
						<SvgTemplate
							class="header-nav-mobile__icon-chats"
							:name="totalUnseenCounter ? 'envelope' : 'envelope-open'"
						/>
					</div>
				</RouterLink>
			</div>
		</nav>
	</header>
</template>

<styles lang="scss" scoped>
@import "./HeaderNavMobile.scss";
</styles>
